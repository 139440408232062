import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import MarkdownBody from '../components/MarkdownBody'
import SEO from '../components/SEO'
import SEOPageTitle from '../components/SEOPageTitle'
import Section from '../components/Section'
import SlimTitle from '../components/SlimTitle'
import SlimWrapper from '../components/SlimWrapper'

const LegalTemplate = ({ data }) => {
  const { title, slug, body, isTermsConditions, metaTitle } = data.contentfulLegalPage
  const postNode = data.contentfulLegalPage

  return (
    <Layout>
      =
      <SEOPageTitle title={title} metaTitle={metaTitle} />
      <SEO pagePath={slug} postNode={postNode} customTitle />
      <Section borderBottom={false}>
        <SlimWrapper>
          <SlimTitle>{title}</SlimTitle>
          <MarkdownBody body={body} />
        </SlimWrapper>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulLegalPage(slug: { eq: $slug }) {
      isTermsConditions
      metaTitle
      slug
      title
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
    }
  }
`

export default LegalTemplate
