import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    margin: 0 auto;
    width: 80%;
  }
`

const SlimWrapper = ({ children }) => <Wrapper>{children}</Wrapper>

export default SlimWrapper
